@use "src/styles/variables";

.profession-card {
  display: flex;
  border-radius: variables.$border-radius;
  overflow: hidden;

  &.LEFT {
    flex-direction: row-reverse;

    & > .image {
      border-radius: variables.$border-radius 0 0 variables.$border-radius;
    }

    @media only screen and (max-width: variables.$breakpoint-tablet) {
      flex-direction: column-reverse;
    }
  }

  &.RIGHT {
    flex-direction: row;

    & > .image {
      border-radius: 0 variables.$border-radius variables.$border-radius 0;
    }

    @media only screen and (max-width: variables.$breakpoint-tablet) {
      flex-direction: column-reverse;
    }
  }

  .profession-card__body {
    width: 50%;
    padding: 4rem;
    background-color: variables.$marketing-light-blue;
    color: variables.$base-900;
    word-break: break-word;
    transition: variables.$transition;

    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: flex-start;
    gap: 1rem;

    .profession-card__body-interests {
      width: 100%;
      margin-top: auto;

      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 1rem;

      @media only screen and (max-width: variables.$breakpoint-mobile) {
        grid-template-columns: 1fr;
      }
    }

    @media only screen and (max-width: variables.$breakpoint-tablet) {
      width: 100%;
      padding: 2rem;
    }
  }

  & > .image {
    width: 50%;
    object-fit: cover;
    transition: variables.$transition;

    @media only screen and (max-width: variables.$breakpoint-tablet) {
      width: 100%;
    }
  }

  &:hover {
    .profession-card__body {
      background-color: variables.$base-300;
    }

    & .image {
      transition: variables.$transition;
      opacity: 0.8;
    }
  }
}
