@use "src/styles/variables";

.main {
  margin-top: 6.5rem;
  overflow: hidden;
  min-height: calc(100vh - 5rem);

  @media only screen and (max-width: variables.$breakpoint-tablet) {
    min-height: calc(100vh - 3.5rem);
    margin-top: 3.5rem;
  }
}
