@use "src/styles/variables";

.nav-drawer {
  width: 100%;
  height: 100vh;
  padding: 1.5rem 2.5rem;

  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: flex-start;
  align-items: flex-start;

  .nav-links {
    list-style-type: none;
    padding: 0;
    margin: 0;

    .nav-drawer__link {
      transition: variables.$transition;

      h2 {
        font-size: 1.8rem;
        padding: 0 0 0.8rem 0;
        margin: 0;
      }
    }
  }

  .nav-sublinks {
    list-style-type: none;
    padding: 1.2rem 0 0 0;
    margin: 0;

    .nav-drawer_sublink {
      transition: variables.$transition;

      h3 {
        font-size: 1.5rem;
        font-weight: normal;
        padding: 0 0 0.8rem 0;
        margin: 0;
      }
    }
  }
}

@media only screen and (max-height: variables.$breakpoint-height) {
  .nav-drawer {
    padding: 1rem 2rem;

    h2 {
      line-height: 1.8rem;
    }

    h3 {
      line-height: 1.5rem;
    }
  }
}
