@use "src/styles/variables";

.dropdown-item {
  width: 100%;
  padding: 0.75rem;

  @include variables.unselectable;

  &:hover {
    background-color: variables.$marketing-light-blue;
  }
}
