@use "src/styles/variables";

.header__container {
  width: 100%;
  height: 6.5rem;
  position: fixed;
  padding: 0 1rem;
  top: 0;
  z-index: 99;
  background-color: variables.$marketing-white;
  transition: transform 1s;

  @media only screen and (max-width: variables.$breakpoint-tablet) {
    height: 3.5rem;
    padding: 0 0.8rem;
  }
}

.header--hidden {
  transform: translateY(-100%);
}

.header--visible {
  transform: translateY(0);
}

.header {
  max-width: variables.$width-layout;
  height: 6.5rem;
  margin: auto;
  padding: 0;
  display: grid;
  grid-template-columns: 10rem auto;
  justify-content: space-between;
  align-items: center;
  justify-items: start;

  @media only screen and (max-width: variables.$breakpoint-tablet) {
    height: 3.5rem;
  }
}

.header__logo {
  height: 3rem;
  width: auto;

  @media only screen and (max-width: variables.$breakpoint-tablet) {
    height: 2rem;
  }
}

.header__logo-link {
  height: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: center;
  align-items: center;
}
