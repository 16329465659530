@use "src/styles/variables";

.dropdown-button {
  padding: 0.5rem 1rem;
  background-color: variables.$marketing-light-blue;
  border-radius: variables.$border-radius;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;

  &.visible {
    border-radius: variables.$border-radius variables.$border-radius 0 0;
  }

  & > h6 {
    @include variables.ellipsis;
  }
}
