@use "normalize";
@use "variables";
@use "typography";
@use "body";

@font-face {
  font-family: 'Material Symbols Outlined';
  font-style: normal;
  font-weight: 400;
  src: url(material-symbols.woff2) format('woff2');
}

/* latin */
@font-face {
  font-family: 'Fellix-Bold';
  font-style: normal;
  font-display: swap;
  src: url(Fellix-Bold.otf) format('opentype');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* latin */
@font-face {
  font-family: 'Fellix-Medium';
  font-style: normal;
  font-display: swap;
  src: url(Fellix-Medium.otf) format('opentype');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}


/* latin */
@font-face {
  font-family: 'Fellix-Regular';
  font-style: normal;
  font-display: swap;
  src: url(Fellix-Regular.otf) format('opentype');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
