@use "src/styles/variables";

.drawer {
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  height: 100vh;

  z-index: 9999;
}

.drawer__active {
  transform: translateX(0);
  transition: 1s;
}

.drawer__inactive {
  transform: translateX(100%);
  transition: 1s;
}

.drawer__container {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: flex-start;
  align-items: flex-start;

  @mixin drawerContainerStyle($font-color, $container-color) {
    background-color: $container-color;
    color: $font-color;
  }

  &.BASE {
    @include drawerContainerStyle(variables.$marketing-white, variables.$marketing-gray);
  }

  &.BASE_INVERTED {
    @include drawerContainerStyle(variables.$marketing-black, variables.$marketing-white);
  }

  &.PRIMARY {
    @include drawerContainerStyle(variables.$marketing-white, variables.$marketing-red);
  }

  &.PRIMARY_INVERTED {
    @include drawerContainerStyle(variables.$marketing-red, variables.$marketing-white);
  }

  &.SECONDARY {
    @include drawerContainerStyle(variables.$base-50, variables.$secondary-700);
  }

  &.SECONDARY_INVERTED {
    @include drawerContainerStyle(variables.$base-900, variables.$secondary-200);
  }
}

.drawer__head {
  width: 100%;
  padding: 0.2rem 0.8rem;
  display: flex;

  .back-button {

    span {
      font-size: 2.5rem;
    }
  }

  .close-button {
    margin-left: auto;
  }
}

.drawer__body {
  width: 100%;
  height: 100%;
}

@keyframes drawer-container-out {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
}

@keyframes drawer-container-in {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(100%);
  }
}
