/** global variables */
$font-family-regular: 'Fellix-Regular', sans-serif;
$font-family-medium: 'Fellix-Medium', sans-serif;
$font-family-bold: 'Fellix-Bold', sans-serif;
$width-content: 80rem;
$width-layout: 80rem;
$breakpoint-mobile: 600px;
$breakpoint-tablet: 800px;
$breakpoint-height: 640px;

/** colors */
/** base colors */
$base-50: #ffffff;
$base-100: #f1f1f1;
$base-200: #e2e2e2;
$base-300: #d6d6d6;
$base-400: #bbbbbb;
$base-500: #656565;
$base-600: #3d3d3d;
$base-700: #343434;
$base-800: #262626;
$base-900: #1b1b1b;

/** primary colors */
$primary-50: #ffa0ab;
$primary-100: #ff7181;
$primary-200: #ff4257;
$primary-300: #ff122e;
$primary-400: #e2001a;
$primary-500: #bc0016;
$primary-600: #970011;
$primary-700: #71000d;
$primary-800: #4b0009;
$primary-900: #260004;

/** secondary colors */
$secondary-50: #bfedfa;
$secondary-100: #7fdbf5;
$secondary-200: #40c8f0;
$secondary-300: #11addb;
$secondary-400: #0c7a9b;
$secondary-500: #07485b;
$secondary-600: #063c4c;
$secondary-700: #05303d;
$secondary-800: #04242e;
$secondary-900: #02181e;

/** marketing colors */
$marketing-red: #cb003d;
$marketing-black: #000000;
$marketing-gray: #343434;
$marketing-white: #ffffff;
$marketing-light-blue: #d1d9e4;

/** competec colors */
$competec-blue: #133D98;
$competec-dark-blue: #0A3787;

/** border */
$border-radius: 0.3125rem;
$border-width: 0.125rem;
$border-style: solid;

/** transition */
$transition: all 250ms ease-in-out 0s;

/** user-select */
@mixin unselectable() {
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
}

@mixin ellipsis() {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
